import React from "react";
import { Vikram, Bhavini, Amit } from '../../assets/Asserts'



function TeamPage() {
    return (
        <>
            <div className="bg-white mt-1 py-12 pt-10 px-8 md:px-10">
                <h1 className=" border-b pb-4 text-3xl uppercase text-gray-800 font-bold">Our Team</h1>

                <div className="mt-10 space-y-12">                    


                    <div className="md:flex gap-6 ">

                        <div className="md:w-1/5 ">
                            <img src={Vikram} className=" rounded-xl" />
                        </div>
                        <div className="mt-3 md:mt-0 md:w-4/5 space-y-3">
                            <h1 className="md:text-2xl text-xl font-bold text-gray-800" >VIKRAM B BALAR, B,COM</h1>
                            <p className="text-lg font-semibold text-gray-700">Lead-Account & Tax compliance advisor</p>
                            <p>Vikram B Balar is a Senior Tax and Accounting Professional, he has 12+ years experience of working as an Accounts manager in various corporates.</p>
                        </div>

                    </div>

                    <div className="md:flex gap-6 ">

                        <div className="md:w-1/5 ">
                            <img src={Bhavini} className=" rounded-xl " />
                        </div>
                        <div className="mt-3 md:mt-0 md:w-4/5 space-y-3">
                            <h1 className="md:text-2xl text-xl font-bold text-gray-800" >BHAVINI J KANKULOL, BSC, LLB</h1>
                            <p className="text-lg font-semibold text-gray-700">Lead-Corporate Law & GST Law</p>
                            <p>Bhavini J Kankulol is a Senior consultant for Corporate law and matters, she has 12+ years experience of corporate advisory and associated with Amit Jeevan & associates from beginning of our Journey. She specialises in Company formation and handling company & GST law related matters.</p>
                        </div>

                    </div>

                    <div className="md:flex gap-6 ">

                        <div className="md:w-1/5 ">
                            <img src={Amit} className=" rounded-xl " />
                        </div>
                        <div className="mt-3 md:mt-0 md:w-4/5 space-y-3">
                            <h1 className="md:text-2xl text-xl font-bold text-gray-800" >AMITKUMAR J KANKULOL (CA)</h1>
                            <p className="text-lg font-semibold text-gray-700">Advisor Consultant</p>
                            <p>Amit J Kankulol is the founder of The Firm M/s. Amit Jeevan & Associates, He is specialising in the field of Income Tax and GST tax matters and disputes with over 12+ years of experience.</p>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default TeamPage;