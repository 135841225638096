import React from "react";
import Team from "../Team/Team";
import Contact from "../Contact/Contact";
import Landing from "../Landing/Landing";
import AboutUs from "../AboutUs/AboutUs";

import Features from "../Service/Service";
import Gallery from "../Gallery/Gallery";
import Testimonial from "../Testimonial/Testimonial";



function Main() {
    return (
        <>
            <Landing />

<AboutUs />

<Features />           

<Team />

{/* <Testimonial /> */}

<Gallery />


<Contact />





</>
)
            
    
}

export default Main;