
import './App.css';
import ErrorPage from './components/ErrorPage/ErrorPage';

import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import Navbar from './components/Navbar/Navbar';
import ServicePage from './components/Service/ServicePage/ServicePage';
import TeamPage from './components/Team/TeamPage/TeamPage';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
    
    
      <div className='bg-gray-50 '>


        <Navbar />

        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route  path="/services/accountingservices" element={<ServicePage path="accountingservices" />}></Route>
          <Route  path="/services/taxcomplianceservices" element={<ServicePage path="taxcomplianceservices" />}></Route>
          <Route  path="/services/virtualcfoservices" element={<ServicePage path="virtualcfoservices" />}></Route>
          <Route  path="/services/financialreporting" element={<ServicePage path="financialreporting" />}></Route>
          <Route path="/teams" element={<TeamPage />}></Route>
          <Route path="/*" element={<ErrorPage />}></Route>
        </Routes>
        
        <Footer />

      </div>


    </>
  );
}

export default App;
