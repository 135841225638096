function ServiceItems(props) {

    const {path,data} = props;

    return (
        <>
            <h1 className=" border-b pb-4 text-3xl uppercase text-gray-800 font-bold">{data[path].title}</h1>
                <p className="mt-7 text-lg max-w-5xl text-gray-800">
                    {data[path].description}   
                </p>
                <ul className="mt-5 px-10 list-disc space-y-3 max-w-5xl text-gray-800">
                    {data[path].keypoints.map(points=><li>{points}</li>)}                  
                </ul>
        </>
    )
}

export default ServiceItems;