import ServiceItems from "./ServiceItems";

function ServicePage(props) {

    const {path} = props;

    const data ={
        "accountingservices":{"title":"Bookkeeping & Accounting",
        
        "description":"We provide bookkeeping, accounting, and financial forecasting services to help businesses plan and prepare for contingencies, set goals, and consolidate their finances accurately. We also assist in identifying operational deficiencies and provide solutions to increase profitability.",
        
        "keypoints":["Budgeting and Forecasting", "Cash Flow Management", "Book keeping and Accounting", "Working Capital Management", "Procure to Pay System: Vendor master management, pricing and contract compliances, a system for invoice verification & approvals, payment processing, expense & travel cost analytics, vendor reconciliations", "Order to Cash System: Customer master management, pricing & billing processes, collection tracking, account receivable analytics, provisioning policy & procedures", "Record to Report System: Quality bookkeeping, complete set of account books & records, bank reconciliations, source documents retention policies, compliance to Indian regulatory requirements, IGAAP, IND-AS, IFRS, periodic closing"]},

        "taxcomplianceservices":{"title":"Tax Compliance services","description":"We offer assistance with direct and indirect tax compliance for businesses, so that owners can focus on their core operations without worrying about complex tax policies. Our team has extensive knowledge of tax laws and stays up-to-date with changing policies, ensuring that clients remain organized and compliant.","keypoints":["We are well-versed in tax laws", "We know complex financial situations and tax implications thereon", "We are accurat in filing returns", "We are expert in filing periodical returns under Income Tax & GST Act"]},

        "virtualcfoservices":{"title":"Virtual CFO Services","description":"Even if your accounting and book-keeping are well-handled, a lack of an experienced senior-level financial oversight may be preventing business growth. Several businesses often reach a point where they need independent and professional guidance, but can not afford a full-time CFO.Bizwin’s Virtual CFO services enables you to work with an experienced finance professional who works with you to help guide your business to success.Our Virtual CFOs provide professional financial management while you focus on growing your company. Our team work closely with your management team and provide strategic advice through a financial lens. This gives balance and strength to the management of your business.","keypoints":[]},

        "financialreporting":{"title":"Financial Reporting" ,"description":"" ,"keypoints":["Financial statement preparation under Indian GAAP, IND-AS or IFRS for single entity or the group", "Preparing consolidated financial statements for subsidiary companies and divisions", "Liaison with Statutory Auditors", "Tailored financial reporting for management purposes and decision-making", "Periodic (monthly/quarterly/half-yearly/Annual) reports, division/ profit Centre-based reporting, project/product-based reporting, employee revenue & contribution analysis and other intelligent reporting & analysis"]}
    }

    


    return (
        <>
            <div className="bg-white mt-1 py-12 pt-10 px-8 md:px-10">

                <ServiceItems path={path} data={data} />


            </div>
        </>
    )
}

export default ServicePage;