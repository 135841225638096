import React from "react";
import { bg } from "../assets/Asserts";

function Landing() {

    return (
        <>
            <div className="mx-auto h-full px-4 py-6 mt-1 md:pb-0 bg-white " id="home">
                <div className="flex flex-col items-center md:mx-12 justify-between lg:flex-row">
                    <div >
                        <div>
                            <h2 className=" text-7xl max-w-lg md:mt-0 text-center font-light leading-snug tracking-tight text-blue-600 md:text-8xl">
                                Welcome <br />
                                to<br />
                                <span className="mt-1 inline-block rounded-md font-semibold">Bizwin Advisors </span>
                            </h2>
                            <p className="text-center text-lg text-gray-700 my-5 md:mb-0 ">Tax Compliance Outsourcing And Consulting Accountants</p>
                        </div>


                    </div>
                    <div className="hidden lg:block lg:w-1/2">
                        <img className="max-w-xl mx-auto" src={bg} />
                    </div>
                </div>
            </div>
        </>
    )

}

export default Landing;