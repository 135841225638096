import { useRef, useState } from 'react'
import { Link } from 'react-router-dom';

function Dropdown(props) {

    const {title,links} = props
    const [open,setOpen] = useState(false);
    const dropdownRef = useRef();

    window.addEventListener("click",(e)=>{
        if(e.target !== dropdownRef.current && open){
            setOpen(false);
        }    
    });

    return (
        <li onClick={()=>{setOpen(!open)}}   className="text-gray-600 relative group hover:text-blue-600">
           <div ref={dropdownRef} className="flex items-center">
           {title}
            <svg ref={dropdownRef} xmlns="http://www.w3.org/2000/svg" className="pl-1  h-4 transition peer-checked:rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
           </div>
            {open && <div x className="absolute left-5 w-60 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                {links.map(link => <Link to={"/services/"+link.url} className="block px-3 py-2 hover:bg-blue-100 text-sm text-gray-700">{link.text}</Link> )}    
            </div>}
        </li>
    )
}

export default Dropdown