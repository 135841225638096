import React from "react";
import { logo } from "../assets/Asserts";
import { Link } from "react-router-dom";
function Footer() {

    return (
        <>
            <footer className="bg-gray-50">
                <div className="mx-auto md:flex gap-20 px-8 md:px-12 py-10">
                    <div className="max-w-sm">
                        <div className="mb-6 flex h-12 items-center space-x-2">
                            <img className="h-10 w-auto " src={logo} alt="Bizwin" />
                        </div>
                        <div className="text-gray-500 space-y-5">
                            <p className="font-medium text-black">Email : </p>
                            info@bizwin.in
                            <p className="font-medium text-black">Contact : </p>
                            +91 7676643738

                        </div>
                    </div>
                    <div className="mt-8 md:mt-0">
                        <h3 className="font-bold text-2xl mt-2 mb-4 text-gray-700">Address</h3>
                        <div className="grid gap-8 md:gap-24 md:grid-cols-3 ">
                            <div >
                                <div className="mt-4 mb-2 font-medium xl:mb-4">Silvassa Office</div>
                                <div className="text-gray-500">
                                    No 1 Radhe Krishna Complex,<br />
                                    Opp. Patel Petrol Pump,<br />
                                    Vapi Silvassa Main Road,<br />
                                    Dadra & Nagar Haveli - 396230.
                                </div>

                            </div>
                            <div >
                                <div className="mt-4 mb-2 font-medium xl:mb-4">Bengaluru Office</div>
                                <div className="text-gray-500">
                                    No. 02 First Floor, <br />
                                    Jayashree Gramoudyog building,<br />
                                    Yamuna Bai Road,<br />
                                    Race Course Road,<br />
                                    Bengaluru - 560001
                                </div>

                            </div>

                            <div >
                                <div className="mt-4 mb-2 font-medium xl:mb-4">Bengaluru Office</div>
                                <div className="text-gray-500">
                                    No. 30 1st Floor,<br />
                                    5th Main Road,<br />
                                    Kasturinagar,<br />
                                    Banaswadi,<br />
                                    Bengaluru - 560043
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
                <div className="bg-gray-100 text-sm md:text-base py-5">
                <p className="text-center text-gray-500">© Copyright Bhavini Vikram & Associates.</p>
                    <p className="text-center text-gray-500">© Copyright Jainetra Fin services Pvt Ltd All Rights Reserved.</p>
                </div>
            </footer>

        </>
    )
}

export default Footer;